.border-beam-container {
    position: absolute;
    inset: 0;
    border: calc(var(--border-width)) solid transparent;
    pointer-events: none;
    border-radius: inherit;
    mask: linear-gradient(transparent, transparent), linear-gradient(white, white);
    mask-clip: padding-box, border-box;
    mask-composite: intersect;
  }
  
  .border-beam-container:after {
    content: '';
    position: absolute;
    aspect-ratio: 1;
    width: calc(var(--size));
    animation: border-beam-animation var(--duration) linear infinite;
    background: linear-gradient(to left, var(--color-from), var(--color-to), transparent);
    offset-anchor: calc(var(--anchor)) 50%;
    offset-path: rect(0 auto auto 0 round calc(var(--size)));
  }
  
  @keyframes border-beam-animation {
    0% {
      offset-distance: 0%;
    }
    100% {
      offset-distance: 100%;
    }
  }
  