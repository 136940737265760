body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-in-out forwards;
}

.delay-500 {
  animation-delay: 0.5s;
}

.delay-1000 {
  animation-delay: 1s;
}

/* Background Gradient */
.breathing-gradient {
  background: linear-gradient(135deg, #000000, #222222, #333333);
  position: relative;
  overflow: hidden;
  height: 100vh;
}

/* Neon Blur Effect */
.neon-blur {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 0, 255, 0.5), rgba(0, 255, 255, 0.5));
  opacity: 0.6;
  pointer-events: none;
  z-index: 0;
  mix-blend-mode: overlay;
}

/* Add multiple neon blurs */
.neon-blur:nth-child(1) {
  width: 200px;
  height: 200px;
  top: 10%;
  left: 10%;
}

.neon-blur:nth-child(2) {
  width: 150px;
  height: 150px;
  bottom: 20%;
  right: 15%;
}

.neon-blur:nth-child(3) {
  width: 100px;
  height: 100px;
  top: 60%;
  right: 5%;
}

.neon-blur:nth-child(4) {
  width: 180px;
  height: 180px;
  bottom: 10%;
  left: 20%;
}

.modal {
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  max-width: 800px;
  width: 90%;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}



@tailwind base;
@tailwind components;
@tailwind utilities;
